<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-if="!getLoading && requestData">
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="my-0" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h6 class="text-h6 grey--text text--darken-1 mb-0">ID: {{ requestData.id }}</h6>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-4" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4 mb-0">{{ requestData.title }}</h1>
        </v-col>
        <v-col cols="auto" class="text-right">
          <h6 class="text-h6 mb-0">
            {{ 'status'|localize }}:
            <v-icon
              :color="STATUS_COLORS[requestData.status]"
              size="20"
              class="mx-2 mb-1"
            >{{ STATUS_ICONS[requestData.status] }}
            </v-icon>
            <span class="font-weight-regular">{{ requestData.status|localize }}</span>
          </h6>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-8 d-flex justify-space-between" align="center" no-gutters>
        <v-col cols="auto" class="d-flex align-center">
          <div class="text-subtitle-1 mb-0">{{ 'reference'|localize }}:</div>
          <div class="mb-0 ml-4"> {{ requestData.request_customer_name }}</div>
        </v-col>
        <v-col v-if="isCreateAvailable" cols="auto" class="d-flex align-center">
          <v-btn :to="{name: 'create-quote', query: {request_id: requestId, mode: transportMode}}" color="primary" depressed rounded>
            <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
            {{ 'createQuote'|localize }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-8 d-flex justify-end" align="center" no-gutters>
        <v-col v-if="isCreateAvailable" cols="auto" class="d-flex align-center">
          <v-btn
            depressed
            rounded
            color="grey lighten-2"
            :loading="declineLoading"
            :disabled="declineLoading"
            @click="$refs.declineRequestDialog.open()">
            {{ 'declineRequest'|localize }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-row class="my-0 mb-1">
          <v-col class="py-0">
            <v-tabs
              v-model="tab"
              @change="$router.replace({query: $router.currentRoute.query, hash: tab})"
              background-color="transparent"
              class="grey--text text--lighten-1"
            >
              <v-tab :ripple="false" href="#quotes" class="text-none">
                {{ 'myQuote'|localize }} & <span class="text-lowercase pl-1">{{ 'bookingSummary'|localize }}</span>
              </v-tab>
              <v-tab :ripple="false" href="#requirements" class="text-none">
                {{ 'requirements'|localize }}
              </v-tab>
            </v-tabs>
            <v-divider/>
          </v-col>
        </v-row>
        <template v-if="tab === 'requirements'">
          <v-container class="px-4 pt-8">
            <v-row class="mt-0 mb-5">
              <v-col cols="4">
                <h6 class="text-subtitle-1 mb-1">{{ 'transportMode'|localize }}</h6>
                <div class="text-body-1 d-flex align-center">
                  <v-icon color="silver-chalice" class="mr-1">{{ TRANSPORT_ICONS[transportMode] }}</v-icon>
                  {{ transportMode|localize }}
                </div>
              </v-col>
              <v-col cols="4">
                <h6 class="text-subtitle-1 mb-1">{{ 'from'|localize }} <span class='font-weight-regular'>({{ requestData.send_from|localize}})</span></h6>
                <div class="text-body-1">{{ requestData.direction_from.full_address }}</div>
              </v-col>
              <v-col cols="4">
                <h6 class="text-subtitle-1 mb-1">{{ 'to'|localize }} <span class='font-weight-regular'>({{ requestData.send_to|localize}})</span></h6>
                <div class="text-body-1">{{ requestData.direction_to.full_address }}</div>
              </v-col>
              <v-col v-if="requestData && requestData.recurring_start" cols="4">
                <h6 class="text-subtitle-1 mb-1">{{ 'recurringNeeds'|localize }}</h6>
                <div class='d-flex mb-2'>
                  <div class="text-body-1 mr-3">{{ 'startDate'|localize }}: {{ requestData.recurring_start || '--' }}</div>
                  <div class="text-body-1">{{ 'endDate'|localize }}: {{ requestData.recurring_end || '--' }}</div>
                </div>
                <div class="text-body-1">{{ 'numberOfShipments'|localize }}: {{ requestData.recurring_shipments || '--' }}</div>
              </v-col>
            </v-row>
            <v-divider class="mb-10"/>
            <v-row class="pb-4">
              <v-col cols="auto">
                <h5 class="text-h5 mb-2">{{ 'goods'|localize }}</h5>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <GoodComponent
                  v-for="(item, index) in requestData.goods"
                  :currentItem="item"
                  :goodTypes="goodTypesSorted.all"
                  :requestType="requestData.request_type"
                  :transportMode="[transportMode]"
                  :item="item"
                  :number="index"
                  :key="item.id"
                  :disabled="true"
                />
              </v-col>
            </v-row>
            <GoodsSummary
              :totalPackages="requestData.goods.length"
              :totalWeight="requestData.weight"
              :totalVolume="requestData.volume"
            />
            <v-divider class="mb-10"/>
            <v-row class="mb-10">
              <v-col cols="auto">
                <h5 class="text-h5">{{ 'expectations'|localize }}</h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'loading'|localize }}</h6>
                <div class="text-body-1 mb-10" v-html="$options.filters.columnizeDates(requestData.loading_date)" />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'unloading'|localize }}</h6>
                <div class="text-body-1 mb-10" v-html="$options.filters.columnizeDates(requestData.unloading_date)" />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'deliveryTerms'|localize }}</h6>
                <div class="text-body-1 mb-10">{{ requestData.delivery_terms }}</div>
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'deadlineForResponse'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  {{ requestData.deadline_for_response_date }} {{ requestData.deadline_for_response_time && `/ ${requestData.deadline_for_response_time}` }} </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'targetPrice'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="currentPriceLimit">
                    {{ currentPriceLimit.currency }} {{ currentPriceLimit.price_limit }}
                  </template>
                  <template v-else>--</template>
                </div>
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'priceToGuaranteedBooking'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="currentPriceLimit && currentPriceLimit['price_guaranteed_booking']">
                    {{ currentPriceLimit.currency }} {{ currentPriceLimit['price_guaranteed_booking'] }}
                  </template>
                  <template v-else>--</template>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-10"/>
            <v-row class="mb-10">
              <v-col cols="auto">
                <h5 class="text-h5">{{ 'requirements'|localize }}</h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1">
                  <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS] }}</v-icon>
                  {{ 'dangerous_goods'|localize }}
                </h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.dangerous_goods_number">
                    UN-{{requestData.dangerous_goods_number}} /
                    <template v-if="requestData['attachment_name']">
                      <a :href="requestData['attachment']" target="_blank">{{requestData['attachment_name']}}</a>
                    </template>
                    <template v-else>#</template>
                  </template>
                  <template v-else>--</template>
                </div>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                  <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS] }}</v-icon>
                  {{ 'tempered_goods'|localize }}
                </h6>
                <div class="text-body-1 mb-10">{{ requestData.tempered_goods ? `${requestData.tempered_goods[0]}ºC - ${requestData.tempered_goods[1]}ºC` : '--' }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1">{{ 'goodsType'|localize }}</h6>
                <div class="text-body-1 mb-10">{{ currentProductType }}</div>
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftLoading'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.tail_lift_loading">{{ 'yes'|localize }}</template>
                  <template v-else>{{ 'no'|localize }}</template>
                </div>
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftDelivery'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.tail_lift_delivery">{{ 'yes'|localize }}</template>
                  <template v-else>{{ 'no'|localize }}</template>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-10"/>
            <v-row>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1">{{'containerLoadingUnloadingSweden'|localize}}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.container_loading_unloading">{{ requestData.container_loading_unloading|localize }}</template>
                  <template v-else>--</template>
                </div>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1">{{ 'customClearance'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.custom_clearance">{{ 'needHelp'|localize }}</template>
                  <template v-else-if="requestData.custom_clearance === false">{{ 'doItOurselves'|localize }}</template>
                  <template v-else>--</template>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeLoading'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.slot_time_loading">{{ requestData.slot_time_loading.from }} - {{ requestData.slot_time_loading.to }}</template>
                  <template v-else>--</template>
                </div>
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeDelivery'|localize }}</h6>
                <div class="text-body-1 mb-10">
                  <template v-if="requestData.slot_time_delivery">{{ requestData.slot_time_delivery.from }} - {{ requestData.slot_time_delivery.to }}</template>
                  <template v-else>--</template>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1">{{ 'attachedFiles'|localize }}</h6>
                <div v-if="requestData.attached_files && requestData.attached_files.length" class="mb-10">
                  <v-chip
                    v-for="(fileData, index) in requestData.attached_files"
                    :key="index"
                    class="mb-2 mr-2"
                    outlined
                    label
                  >
                    <a :href="fileData.attachment" target="_blank" class="text-truncate black--text">{{ fileData.attachment_name }}</a>
                  </v-chip>
                </div>
                <div v-else class="text-body-1 mb-10">--</div>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-1">{{ 'otherInformation'|localize }}</h6>
                <div class="text-body-1 mb-10">{{ requestData.other_information ? requestData.other_information : '--' }}</div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <CompanyQuotes :requestStatus="requestData.status" />
        </template>
      </div>
    </template>
    <SimpleDialog
      ref="declineRequestDialog"
      :title="'declineRequest'|localize"
      :text="'declineRequestText'|localize"
      :accept-text="'decline'|localize"
      :decline-text="'back'|localize"
      @accept="declineRequest"
    />
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import {STATUSES, STATUS_ICONS, STATUS_COLORS} from '@/enums/statuses';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import GoodComponent from '@/components/GoodComponent';
  import GoodsSummary from '@/components/GoodsSummary';
  import CompanyQuotes from '@/components/CompanyQuotes.vue';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import methods from '@/helpers/methods';
  import {
    SPECIAL_REQUIREMENTS_COLORS,
    SPECIAL_REQUIREMENTS_ICONS,
    SPECIAL_REQUIREMENTS_TYPES,
  } from '@/enums/specialRequirements';

  export default {
    name: 'CompanyRequestDetails',
    components: {GoodComponent, CompanyQuotes, GoodsSummary, SimpleDialog},

    data() {
      return {
        STATUSES,
        STATUS_ICONS,
        STATUS_COLORS,
        TRANSPORT_ICONS,
        transportMode: this.$router.currentRoute.query.mode,
        requestId: this.$router.currentRoute.query.request_id,
        getLoading: false,
        requestData: null,
        tab: this.initialHash(),
        declineLoading: false
      };
    },

    async mounted() {
      try {
        this.getLoading = true;
        let remoteSingleData = await api.getSingleCompanyRequest({'request_id': this.requestId, 'company_id': this.userData.company.id, 'transport_mode': this.transportMode});
        this.requestData = remoteSingleData.data;
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_TYPES() {
        return SPECIAL_REQUIREMENTS_TYPES
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('myRequests'),
            disabled: false,
            to: {name: 'company-requests'}
          },
          {
            text: localize('requestDetails'),
            disabled: true,
          },
        ]
      },
      productTypes() {
        return this.$store.getters.productTypes;
      },
      currentPriceLimit() {
        return this.requestData.price_limit.filter(item => item.transport_mode === this.transportMode)[0];
      },
      currentProductType() {
        if (this.requestData.goods_type) {
          let existCheck = this.productTypes.findIndex(type => type.key === this.requestData.goods_type);
          if (existCheck >= 0) {
            return localize(this.requestData.goods_type);
          } else {
            return this.requestData.goods_type;
          }
        }
      },
      goodTypesSorted() {
        return this.$store.getters.goodTypesSorted;
      },
      isCreateAvailable() {
        return (
          (this.requestData.status === STATUSES.NEW) ||
          (this.requestData.status === STATUSES.PENDING) ||
          (this.requestData.status === STATUSES.NEED_RESPONSE)
        )
      },
      userData() {
        return this.$store.getters.userData;
      },
      declineData() {
        let declineData = {};
        declineData.request_id = Number(this.requestId);
        declineData.company_id = this.userData.company.id;
        declineData.transport_mode = this.transportMode;
        return declineData;
      }
    },

    methods: {
      async declineRequest() {
        try {
          this.declineLoading = true;
          await api.declineRequest(this.declineData);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'company-requests'});
        } catch (e) {
          this.declineLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      initialHash() {
        const queryHash = this.$router.currentRoute.hash.slice(1);
        return queryHash ? queryHash : 'quotes';
      },
    },
  };
</script>
